<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-card class="elevation-x">
    <v-card-title class=" font-alt pb-3">Professionnels</v-card-title>
    <v-card-subtitle class="o-60">Liste des professionnels activés et désactivés</v-card-subtitle>
    <!--    <div class="search-list">-->
    <!--      <v-btn fab x-small color="grey lighten-3" class="elevation-x">-->
    <!--        <font-awesome-icon :icon="['fad', 'search']" size="lg" />-->
    <!--      </v-btn>-->
    <!--    </div>-->
    <skeleton-list v-if="loading" />
    <v-list v-if="!loading && professionals" class="pa-0">
      <v-list-item two-line class="click-to-create" :class="$vuetify.theme.dark ? '' : 'grey lighten-4'"
                   @click.stop="professionalForm = true"
      >
        <v-list-item-avatar>
          <v-avatar size="40" color="success lighten-1">
            <font-awesome-icon :icon="['fas', 'plus']" size="1x" class="white--text" />
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-alt font-weight-medium">Ajouter un professionnel</v-list-item-title>
          <v-list-item-subtitle class="op-60 font-weight-light">Créer un nouveau professionnel</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <empty-list v-if="professionals.length === 0" title="Aucun professionnel" subtitle="L'établissement ne possède aucun professionnel" />
      <template v-for="(professional, index) in professionals">
        <v-list-item :key="professional.id" two-line @click.stop="select(professional)">
          <v-list-item-avatar>
            <v-avatar color="grey lighten-2" size="40" class="white--text">
              {{ nameToInitials(professional.firstname + ' ' +professional.lastname) }}
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-alt">{{ professional.firstname }} {{ professional.lastname }}</v-list-item-title>
            <v-list-item-subtitle v-if="professional.services.length" class="flex-wrap d-flex">
              <template v-for="(service, id) in professional.services">
                <v-chip v-if="establishmentId === service.establishment" :key="id" x-small class="op-60 font-weight-light mr-1">
                  <span>{{ service.label }}</span>
                </v-chip>
              </template>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-else class="op-60 font-weight-light">Non renseigné</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="index < professionals.length -1" :key="index" />
      </template>
      <div v-if="totalItems > itemsPerPage" class="py-3 d-flex justify-center">
        <v-pagination
          v-model="page"
          :length="pageCount"
          total-visible="5"
          color="grey"
          @input="loadProfessionals"
        />
      </div>
    </v-list>
    <professional-form v-if="professionalForm" :professional="item" @close="closeDialog" @update-professional="updateListItem" @add-professional="addListItem" />
  </v-card>
</template>

<script>
  import EmptyList from '@/modules/core/components/EmptyList'
  import SkeletonList from '@/modules/core/components/SkeletonList'
  import ProfessionalForm from '@/modules/professionals/components/backoffice/ProfessionalForm'
  import * as professionals from '@/modules/professionals/api/doctors'
  import {removeItem} from '@/utils/array'
  import dataDisplayers from '@/mixins/dataDisplayers'

  export default {
    name: 'ProfessionalsList',
    components: {ProfessionalForm, SkeletonList, EmptyList},
    mixins: [dataDisplayers],
    data() {
      return {
        professionalForm: false,
        loading: false,
        professionals: null,
        item: null,
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        totalItems: 0,
        establishmentId: ''
      }
    },
    watch:{
      '$store.state.establishments.activeEstablishmentId': {
        handler() {
          this.page = 1
          this.loadProfessionals()
        },
        immediate: true
      }
    },
    methods: {
      closeDialog() {
        this.professionalForm = false
        this.item = null
        this.remove()
      },
      async loadProfessionals() {
        this.establishmentId = this.$store.state.establishments.activeEstablishmentId
        if(!this.establishmentId) {
          return
        }
        this.loading = true
        try {
          await professionals.list({
            params: {
              page: this.page,
              itemsPerPage: this.itemsPerPage,
              'services.establishment': this.$store.state.establishments.activeEstablishmentId
            }
          }).then( (response) => {
            this.professionals = response.data['hydra:member']
            this.totalItems = response.data['hydra:totalItems']
            this.pageCount = Math.ceil(this.totalItems / this.itemsPerPage)
          })
        } catch (error) {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/components/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue'
          })
        } finally {
          this.loading = false
        }
      },
      remove(professional) {
        removeItem(this.professionals, professional)
      },
      select(professional) {
        this.professionalForm = true
        this.item = professional
      },
      updateListItem(data){
        if (data) {
          const dataIndex = this.professionals.findIndex(professional => professional.id === data.id)
          if(dataIndex === -1) {
            this.professionals.push(data)
          }
          this.professionals[dataIndex] = data
          if(data.services.length === 0) {
            removeItem(this.professionals, data)
          }
        }
        return false
      },
      addListItem(data){
        if (data && data.services.length) {
          this.professionals.push(data)
        }
        return false
      }
    }
  }
</script>
