<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-autocomplete v-model="localValue" label="Rechercher un professionnel"
                  :loading="searching"
                  :search-input.sync="search"
                  item-value="id"
                  :item-text="getItemText"
                  hide-no-data outlined dense rounded
                  hide-details="auto"
                  no-filter
                  clearable
                  :items="items"
                  return-object
  >
    <template v-slot:item="{ item, on, attrs }">
      <v-list-item transition="slide-y-transition" v-bind="attrs" v-on="on">
        <v-list-item-content>
          <v-list-item-title>{{ item.firstname }} {{ item.lastname }}</v-list-item-title>
          <v-list-item-subtitle>{{ item.rpps }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-if="!endReached" v-slot:append-item>
      <div v-intersect="loadMore">
        <v-skeleton-loader
          ref="skeleton"
          class="mx-auto"
          type="list-item-avatar-three-line"
        />
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
  import * as doctors from '@/modules/professionals/api/doctors'

  export default {
    name: 'ProfessionalAutocomplete',
    data() {
      return {
        searching: false,
        items: this.value || [],
        search: null,
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        totalItems: 0,
        endReached: false
      }
    },
    computed: {
      localValue: {
        set(value) {
          this.$emit('input', value)
        },
        get() {
          return this.value
        }
      }
    },
    created() {
      this.loadDoctors()
    },
    methods: {
      getItemText(item) {
        return `${item.firstname} ${item.lastname}`
      },
      async loadDoctors() {
        this.$emit('change')
        try {
          this.searching = true
          await doctors.list({
            params: {
              page: this.page,
              itemsPerPage: this.itemsPerPage
            }
          }).then((response) => {
            this.items = [
              ...this.items,
              ...response.data['hydra:member']
            ]
            this.totalItems = response.data['hydra:totalItems']
            this.pageCount = Math.ceil(this.totalItems / this.itemsPerPage)
          })
        } catch (error) {
          this.$root.$emit('error', error)
        } finally {
          this.searching = false
        }
      },
      loadMore () {
        this.page += 1
        this.loadDoctors()
        if(this.items.length === this.totalItems) {
          this.endReached = true
        }
      }
    }
  }
</script>
