<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-autocomplete v-model="localValue" label="Rechercher une spécialité"
                  :loading="searching"
                  :search-input.sync="search"
                  item-value="id"
                  item-text="label"
                  hide-no-data outlined dense rounded
                  hide-details="auto"
                  no-filter
                  clearable
                  :items="items"
                  return-object
                  multiple
  >
    <template v-slot:item="{ item, on, attrs }">
      <v-list-item transition="slide-y-transition" v-bind="attrs" v-on="on">
        <v-list-item-content>
          <v-list-item-title>{{ item.label }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
  // import {empty} from '@/utils/variables'
  // import {throttle} from 'throttle-debounce'
  import * as specialities from '@/modules/specialities/api'

  export default {
    name: 'SpecialitiesAutocomplete',
    props: {
      value: Array
    },
    data() {
      return {
        searching: false,
        items: this.value || [],
        search: null
      }
    },
    computed: {
      localValue: {
        set(value) {
          this.$emit('input', value)
        },
        get() {
          return this.value
        }
      }
    },
    // watch: {
    //   search: throttle(300,false, async function (val) {
    //     if (!empty(val) && (!this.value || val !== this.value.label)) {
    //
    //     }
    //   })
    // },
    created() {
      this.loadSpecialties()
    },
    methods: {
      async loadSpecialties() {
        this.$emit('change')
        try {
          this.searching = true
          this.items = (await specialities.list()).data['hydra:member']
        } catch (e) {
          this.$root.$emit('error', e)
        } finally {
          this.searching = false
        }
      }
    }
  }
</script>
