<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-dialog :value="true" max-width="550" transition="dialog-bottom-transition" persistent>
    <v-card>
      <v-icon small color="white" class="close-submit-dialog" @click="close">$close</v-icon>
      <v-list class="pa-0">
        <v-img class="p-abs img-header" src="@/assets/img/login-default-bg.png" min-width="250" width="100%" max-height="70px" />
        <div class="title-header">
          <v-list-item-title class="text-h5 font-weight-light white--text px-5 p-rel white--text pt-2">Professionnel</v-list-item-title>
          <v-list-item-subtitle class="text-caption font-alt mb-1 px-5 white--text p-rel">
            {{ professional ? 'Modifier les informations du professionnel' : 'Créer un nouveau professionnel' }}
          </v-list-item-subtitle>
        </div>
        <div class="splitter" />
        <v-form ref="professional" lazy-validation class="mt-2" @submit.prevent="submit">
          <v-row class="ma-0">
            <v-col cols="12">
              <professional-autocomplete v-model="doctor" @input="selectDoctor" />
            </v-col>
            <v-col cols="12" md="6">
              <text-field v-model="model.firstname" rounded label="Prénom du professionnel"
                          :rules="[$rules.required, $rules.maxLength(200)]"
              />
            </v-col>
            <v-col cols="12" md="6">
              <text-field v-model="model.lastname" rounded label="Nom du professionnel"
                          :rules="[$rules.required, $rules.maxLength(200)]"
              />
            </v-col>
            <v-col cols="12">
              <text-field v-model="model.rpps" rounded label="Numéro RPPS"
                          :rules="[$rules.required, $rules.length(11), $rules.digit]"
              />
            </v-col>
            <v-col cols="12">
              <services-autocomplete v-model="model.services" dense />
            </v-col>
            <v-col cols="12">
              <specialities-autocomplete v-model="model.specialities" dense />
            </v-col>
          </v-row>
          <v-card-actions class="px-3">
            <v-spacer />
            <v-btn x-small rounded text @click="close">
              Annuler
            </v-btn>
            <v-btn x-small rounded color="success" type="submit" :loading="submitting" :disabled="submitting">
              Enregistrer
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
  import ProfessionalAutocomplete from '@/modules/professionals/components/backoffice/ProfessionalAutocomplete'
  import SpecialitiesAutocomplete from '@/modules/specialities/components/SpecialitiesAutocomplete'
  import ServicesAutocomplete from '@/modules/services/components/ServicesAutocomplete'
  import {create, update} from '@/modules/professionals/api/doctors'
  import {column} from '@/utils/array'
  import TextField from '@/modules/core/components/TextField'
  export default {
    name: 'ProfessionalForm',
    components: {TextField, ServicesAutocomplete, SpecialitiesAutocomplete, ProfessionalAutocomplete},
    props: {
      professional: Object
    },
    data() {
      return{
        submitting: false,
        doctor: {},
        model: {
          firstname: this.professional ? this.professional.firstname : '',
          lastname: this.professional ? this.professional.lastname : '',
          rpps: this.professional ? this.professional.rpps : '',
          services: this.professional ? this.professional.services : [],
          specialities: this.professional ? this.professional.specialities : []
        }
      }
    },
    methods: {
      selectDoctor(){
        this.model.firstname = this.doctor.firstname
        this.model.lastname = this.doctor.lastname
        this.model.rpps = this.doctor.rpps
        this.model.services = this.doctor.services
        this.model.specialities = this.doctor.specialities
      },
      async submit() {
        if (!this.$refs.professional.validate()) {
          return false
        }
        try {
          this.submitting = true
          let data = {...this.model}
          data.services = column(this.model.services, '@id')
          data.specialities = column(this.model.specialities, '@id')
          let response = this.professional || this.doctor.id ? await update(this.professional ? this.professional.id : this.doctor.id ,data) : await create(data)
          if(this.professional || this.doctor.id) {
            this.$emit('update-professional',  response.data)
          } else {
            this.$emit('add-professional',  response.data)
            this.$store.dispatch('resetStats', this.$store.state.establishments.activeEstablishmentId)
          }
          this.close()
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/components/Snackbar'),
            type: 'success',
            title: this.professional ? 'Professionnel modifié avec succès.' : 'Professionnel crée avec succès.'
          })
        } catch (error) {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/components/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue'
          })
        } finally {
          this.submitting = false
        }
      },
      close() {
        this.$emit('close')
      }
    }
  }
</script>
